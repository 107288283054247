$(document).ready(function () {

    var owlHeader = $('#owl-carousel-header');
    owlHeader.owlCarousel({
        rtl: $("html").attr("dir") == "rtl" ? true : false,
        loop: true,
        items: 1,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        afterAction: function (el) {
            debugger;
            console.log(this.$owlItems);
            this.$owlItems.addClass('item001');
            this.$owlItems
            console.log(this.$owlItems);
        },
        responsive: {

        }
    })

    $("#owl-carousel-header .active .header-left-item").addClass("animated").addClass("slideInLeft").addClass("headerAnim");
    $("#owl-carousel-header .active .header-right-item").addClass("animated").addClass("slideInRight").addClass("headerAnim");
    owlHeader.on("translate.owl.carousel", function () {
        //   $("#owl-carousel-header .active .item-bg").removeClass("header-animation");
        $("#owl-carousel-header .active .header-left-item").addClass("animated").removeClass("headerAnim").removeClass("slideInLeft");
        $("#owl-carousel-header .active .header-right-item").addClass("animated").removeClass("headerAnim").removeClass("slideInRight");
    });
    owlHeader.on("translated.owl.carousel", function () {
        $("#owl-carousel-header .active .header-left-item").addClass("animated").addClass("slideInLeft").addClass("headerAnim");
        $("#owl-carousel-header .active .header-right-item").addClass("animated").addClass("slideInRight").addClass("headerAnim");

    });

    // owlHeader.on('changed.owl.carousel', function(event) {
    //     // debugger;
    //     // console.log(this.$owlItems);
    //     // this.$owlItems.addClass('item001');
    //     // this.$owlItems
    //     // console.log(this.$owlItems);
    // })
    // $("#owl-carousel-header .active .item-bg").addClass("headerAnim");

    $('#modules-slider').owlCarousel({
        rtl: $("html").attr("dir") == "rtl" ? true : false,
        loop: true,
        items: 3,
        margin: 0,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        // animateIn: 'fadeIn',
        // animateOut: 'fadeOut',
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            991: {
                items: 3
            },

        }
    })


    var map;
    function initMap() {
        map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: 31.224617, lng: 29.954153 },
            zoom: 8
        });
    }
    initMap();

})

var duration = 50;

$(window).scroll(function () {
    $(".slideanim").each(function (i) {
        var _this = this;

        var pos = $(this).offset().top;
        var winTop = $(window).scrollTop();
        var screenHeight = window.innerHeight - 50;
        if (pos < winTop + screenHeight) {
            $(this).addClass("animated").addClass("slideInUp").addClass("slide");
            // $(this).delay( i*(duration/2)); 
            // setTimeout(  , duration );
            // setTimeout(function() {
            //     // debugger;
            //     console.log(duration);
            //     $(_this).addClass("animated").addClass("slideInUp").addClass("slide") ;
            // }, duration = duration + i);
        }
    });
});



// navigation dropdown opens on hover
function toggleDropdown (e) {
    let _d = $(e.target).closest('.dropdown.nav-dropdown'),
        _m = $('.dropdown-menu', _d);
    setTimeout(function(){
      let shouldOpen = e.type !== 'click' && _d.is(':hover');
      _m.toggleClass('show', shouldOpen);
      _d.toggleClass('show', shouldOpen);
      $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
    }, e.type === 'mouseleave' ? 300 : 0);
  }
  
  $('body')
    .on('mouseenter mouseleave','.dropdown.nav-dropdown',toggleDropdown)
    .on('click', '.dropdown-menu a', toggleDropdown);